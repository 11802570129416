$search-background:                                    url("/icons/search.svg") !default;

// Adapted from https://github.com/h-enk/doks

@include media-breakpoint-up(md) {
    .search {
        max-width: 20rem;
        margin-top: 0.125rem;
        margin-bottom: 0.125rem;
    }

    .search-modal .search {
        max-width: 100% !important;
    }
}

.form-control.is-search {
    padding-right: 4rem;
    border: 1px solid transparent;
    background: var(--bs-tertiary-bg);
}

.form-control.is-search:focus {
    border: 1px solid var(--bs-primary);
}

.search::after {
    position: absolute;
    top: 0.4625rem;
    right: 0.5375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    padding-right: 0.3125rem;
    padding-left: 0.3125rem;
    font-size: $font-size-base * 0.75;
    color: var(--bs-secondary-color);
    content: "Ctrl + /";
    border: 1px solid var(--bs-border-color);
    border-radius: 0.25rem;
}

.navbar-form {
    position: relative;
}

.search-suggestions {
    position: absolute;
    left: 0;
    margin-top: 0.5rem;
    width: calc(100vw - 3rem);
    z-index: $zindex-dropdown;
}

.search-modal .search-suggestions {
    position: relative;
    width: 100% !important;
}

.search-suggestions a,
.suggestion__no-results {
    padding: 0.75rem;
    margin: 0 0.5rem;
}

.search-suggestions a {
    display: block;
    text-decoration: none;
}

.search-suggestions a:focus {
    background-color: var(--bs-tertiary-bg);
    outline: 0;
}

.search-suggestions div:not(:first-child) {
    border-top: 1px dashed var(--bs-border-color);
}

.search-suggestions div:first-child {
    margin-top: 0.5rem;
}

.search-suggestions div:last-child {
    margin-bottom: 0.5rem;
}

.search-suggestions a:hover {
    background-color: var(--bs-tertiary-bg);
}

.search-suggestions span {
    display: flex;
    font-size: $font-size-base;
}

.suggestion__title {
    font-weight: $headings-font-weight;
}

.suggestion__description,
.suggestion__no-results {
    color: var(--bs-secondary-color);
}

@include media-breakpoint-up(sm) {
    .search-suggestions {
        width: 31.125rem;
    }

    .search-modal .search-suggestions {
        width: 100% !important;
    }

    .search-suggestions a {
        display: flex;
    }

    .suggestion__title {
        width: 9rem;
        padding-right: 1rem;
        border-right: 1px solid var(--bs-border-color);
        display: inline-block;
        text-align: right;
    }

    .search-modal .suggestion__title {
        width: 15rem !important;
    }

    .suggestion__description {
        width: 19rem;
        padding-left: 1rem;
    }

    .search-modal .suggestion__description {
        width: 100% !important;
    }
}

.search-background {
    --image-opacity: 0.1;
}

.search-background:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: var(--image-opacity);
    background-image: #{$search-background};
    background-position: center;
    background-size: 25%;
    background-repeat: no-repeat;
}
