$base-url: unquote("/");
$danger: #dc3545;
$dark-mode-shade: 20%;
$dark-mode-tint: 40%;
$dark: #212529;
$enable-dark-mode: unquote("true");
$font-size-base: 1rem;
$import-fonts: unquote("true");
$info: #0dcaf0;
$light: #f8f9fa;
$navbar-height: 5.5rem;
$navbar-offset-xs: 5.5rem;
$navbar-offset: 5.5rem;
$navbar-size: unquote("md");
$overlay-offset: 5.5rem;
$primary: #d43900;
$secondary: #6c757d;
$success: #198754;
$theme-font: unquote("Inter");
$warning: #ffc107;