/* Adapted from PrismJS 1.29.0
https://prismjs.com/download.html#themes=prism&plugins=command-line */

/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */

.command-line-prompt {
    border-right: 1px solid #999;
    display: block;
    float: left;
    font-size: 100%;
    letter-spacing: -1px;
    margin-right: 1em;
    pointer-events: none;
    text-align: right;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.command-line-prompt > span::before {
    opacity: 0.7;
    content: " ";
    display: block;
    padding-right: 0.8em;
}

.command-line-prompt > span[data-prompt]::before {
    content: attr(data-prompt);
}

.command-line-prompt > span[data-continuation-prompt]::before {
    content: attr(data-continuation-prompt);
}

.command-line span.token.output {
    /* Make shell output lines a bit lighter to distinguish them from shell commands */
    opacity: 0.7;
}
