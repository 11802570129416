// Define template variables
@import "hugo:vars";

// Include default variable overrides
@import "common/variables.scss";
@import "theme/variables.scss";
@import "common/icons.scss";

// Import Bootstrap configuration (mounted by core Bootstrap module)
@import "bootstrap.scss";

// Include dark mode overrides
@import "common/variables-dark.scss";

// Import Hinode theme styles
@import "components/abbr.scss";
@import "components/alert.scss";
@import "components/blockquote.scss";
@import "components/breadcrumb.scss";
@import "components/button.scss";
@import "components/card.scss";
@import "components/carousel.scss";
@import "components/clipboard.scss";
@import "components/command.scss";
@import "components/feature.scss";
@import "components/footer.scss";
@import "components/nav.scss";
@import "components/navbar.scss";
@import "components/img.scss";
@import "components/pagination.scss";
@import "components/persona.scss";
@import "components/popover.scss";
@import "components/sidebar.scss";
@import "components/syntax.scss";
@import "components/table.scss";
@import "components/timeline.scss";
@import "components/toc.scss";
@import "components/video.scss";
@import "common/animation.scss";
@import "common/masonry.scss";
@import "common/scrollbar.scss";
@import "common/styles.scss";
@import "layouts/reboot.scss";
@import "layouts/type.scss";
@import "common/export.scss";
@import "helpers/colored-links.scss";
@import "helpers/display.scss";


    @import "theme/fonts.scss";


// Import theme placeholders
@import "theme/base.scss";
@import "theme/theme.scss";

// Import Bootstrap utilities API (mounted by core Bootstrap module)
@import "modules/bootstrap/utilities/api";


$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`

$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 1024px
);

$headings-font-weight:        500 !default;

$zindex-dropdown:             1000 !default;

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
      @media (min-width: $min) {
        @content;
      }
    } @else {
      @content;
    }
  }


@import "modules/flexsearch/flexsearch.scss";


@import "modules/fontawesome/svg-with-js"; 



// define wrapper for fluid icons
.fa-wrapper {
    container: wrapper / inline-size;
}

// define fallback size for browsers not supporting containers
.fa-fluid {
    font-size: 5rem;
}

// set size based on container width
@container (min-width: 0) {
    .fa-wrapper .fa-fluid {
        font-size: 60cqi;
    }
}
