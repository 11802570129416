// scss-docs-start sidebar
.sidebar {
    top: var(--navbar-offset);
}

.sidebar-overflow {
    top: var(--navbar-offset);
    max-height: 90vh;
    overflow-y: auto;
}

.sidebar-item {
    color: rgba(0, 0, 0, 0.65);
    margin-left: 0 !important;
    padding-left: 0.85rem !important;
    display: inline-block;

    &.active,
    &:hover,
    &:focus {
        color: black;
    }
}

.btn-toggle {
    padding: 0.25rem 0.5rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.65);
    background-color: transparent;

    &:hover,
    &:focus {
        color: black;
        background-color: tint-color($primary, 90%);
    }

    &::before {
        width: 1.25em;
        line-height: 0;
        content: $btn-toggle;
        transition: transform 0.35s ease;
        transform-origin: 0.5em 50%;
    }
}

.btn-toggle[aria-expanded="true"] {
    color: black;

    &::before {
        transform: rotate(90deg);
    }
}

.btn-toggle-nav a {
    padding: 0.1875rem 0.5rem;
    margin-top: 0.125rem;
    margin-left: 1.25rem;

    &:hover,
    &:focus {
        background-color: tint-color($primary, 90%);
    }

    &.active {
        font-weight: bold;
    }
}

@if $enable-dark-mode {
    @include color-mode(dark) {
        .sidebar-item {
            color: var(--bs-body-color);
            margin-left: 0 !important;
            padding-left: 0.85rem !important;
        
            &.active,
            &:hover,
            &:focus {
                color: white !important;
                background-color: transparent;
            }
        }

        .btn-toggle {
            color: var(--bs-body-color);
        
            &:hover,
            &:focus {
                color: white !important;
                background-color: transparent;
            }
        
            &::before {
                content: $btn-toggle-dark;
            }
        }

        .btn-toggle[aria-expanded="true"] {
            color: var(--bs-secondary-color);
        }

        .btn-toggle-nav a {
            padding: 0.1875rem 0.5rem;
            margin-top: 0.125rem;
            margin-left: 1.25rem;

            &:hover,
            &:focus {
                color: white !important;
                background-color: transparent;
            }

            &.active {
                font-weight: bold;
            }
        }
    }
}

// scss-docs-end sidebar

.dropdown-toggle {
    outline: 0;
}
