//
// Table of contents sidebar & drop-down panel
//
// scss-docs-start toc
.toc-sidebar {
    grid-area: toc;
    right: 0;
    z-index: 2;
    height: calc(100vh - 5rem);
    overflow-y: auto;
    top: 5rem;
}

// scss-docs-end toc

.toc nav {
    font-size: 0.875rem;
    margin-bottom: -0.875rem;
}

.toc nav ul {
    padding-left: 0;
    list-style: none;
}

.toc nav ul ul {
    padding-left: 1rem;
    margin-top: 0.25rem;
}

.toc nav li {
    margin-bottom: 0.25rem;
}

.toc nav a {
    color: inherit;

    &:hover {
        color: var(--bs-primary);
    }
}

.toc nav a:not(:hover) {
    text-decoration: none;
}

.toc nav a code {
    font: inherit;
}

#toc-collapse {
    border-color: var(--bs-secondary-color) !important;
    border-radius: 0 0 var(--bs-border-radius) var(--bs-border-radius) !important;
    margin-top: -1.5rem;
    padding-top: 1.5rem !important;
}

.toc-button {
    --bs-btn-hover-color: var(--bs-primary);
    --bs-btn-hover-bg: var(--bs-body-bg);
    --bs-btn-hover-border-color: var(--bs-primary);
    --bs-btn-active-color: var(--bs-primary);
    --bs-btn-active-bg: var(--bs-body-bg);
    --bs-btn-active-border-color: var(--bs-primary);

    background-color: var(--#{$prefix}body-bg) !important;

    &.active,
    &:hover,
    &:focus {
        color: var(--bs-primary);
        border-color: var(--bs-primary);
        box-shadow: 0 0 0 4px var(--bs-primary-border-subtle);
        outline: none;
    }
}

@if $enable-dark-mode {
    [data-bs-theme="dark"] {
        .toc-button {
            --bs-btn-color: var(--bs-body-color);
            --bs-btn-border-color: var(--bs-body-color);

            &:hover {
                background-color: transparent;
            }
        }

        #toc-collapse {
            border-color: var(--bs-body-color) !important;
        }

        .toc nav a {
            color: var(--bs-body-color);

            &:hover {
                color: white;
            }
        }
    }
}

.section-menu > .nav-link.active, .section-menu > .nav-link:hover {
    color: var(--bs-primary);
}
