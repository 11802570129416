// stylelint-disable selector-pseudo-class-no-unknown

// scss-docs-start export
:hinode-theme {
    --accordion-icon-active-color: #{$accordion-icon-active-color};
    --accordion-icon-active-color-dark: #{$gray-300};
    --accordion-icon-color: #{$accordion-icon-color};
    --accordion-icon-color-dark: #{$gray-600};
    --btn-close-color: #{$btn-close-color};
    --btn-toggle-color: #{$btn-toggle-color};
    --btn-toggle-color-dark: #{$gray-600};
    --carousel-control-color: #{$carousel-control-color};
    --form-check-input-checked-color: #{$form-check-input-checked-color};
    --form-check-input-indeterminate-color: #{$form-check-input-indeterminate-color};
    --form-feedback-icon-invalid-color: #{$form-feedback-icon-invalid-color};
    --form-feedback-icon-valid-color: #{$form-feedback-icon-valid-color};
    --form-select-indicator-color: #{$form-select-indicator-color};
    --form-select-indicator-color-dark: #{$form-select-indicator-color-dark};
    --form-switch-checked-color: #{$form-switch-checked-color};
    --form-switch-color: #{$form-switch-color};
    --form-switch-color-dark: #{$form-switch-color-dark};
    --form-switch-focus-color: #{$form-switch-focus-color};
    --navbar-dark-color: #{$navbar-dark-color};
    --navbar-light-icon-color: rgba($body-color, 0.75); // TODO: See https://github.com/twbs/bootstrap/pull/37720
}

// scss-docs-end export
// stylelint-enable selector-pseudo-class-no-unknown
