// scss-docs-start colored-links
$custom-colors: (
    "primary": "light", 
    "secondary": "light", 
    "success": "light", 
    "danger": "light", 
    "dark": "light", 
    "black": "light",
    "info": "dark", 
    "warning": "dark", 
    "light": "dark", 
    "white": "dark",
    "body": "adaptive",
    "body-tertiary": "adaptive"
);

@each $color, $value in $custom-colors {
    $main-color: var(--bs-body-color);
    $emphasize-color: var(--bs-secondary-color);

    @if $value == "light" {
        $main-color: $white;
        $emphasize-color: shade-color($main-color, $link-shade-percentage);
    } @else if $value == "dark" {
        $main-color: $black;
        $emphasize-color: tint-color($main-color, $link-shade-percentage);
    }

    .link-bg-#{$color} {
        color: $main-color if($enable-important-utilities, !important, null); 

        &:hover,
        &:focus {
            color: $emphasize-color if($enable-important-utilities, !important, null);
        }
    }
}

// scss-docs-end colored-links
